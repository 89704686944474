import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import ContactForm from '../components/ContactForm'
import AgentvsSellFastSactable from '../components/AgentvsSellFastSacTable'
import SeeTheDifferenceSidebar from '../components/SeeTheDifferenceSidebar'
import SellingToAProfessional from '../img/selling-to-a-professional.jpeg'
import FiveWayForeclosure from '../img/5-ways-foreclosure.png'


const ResourcePage = () => {

  return (
    <Layout headerTransparent={false}>
      <SEO
        title={'Resources | Sell Your Home For Cash Sacramento'}
        description={"We've compiled some resources on this page to help you sell your house fast or save you from foreclosure. We work with homeowners every day who need to sell…"}
        keywords={''}
      />


      <section className="py-16 section-stripe-bg bg-gray-50 ">
        <div className="flex flex-wrap px-6 md:px-8 container mx-auto ">
          <div className="w-full md:w-2/3 md:pr-4 prose">



            <h1 class="has-text-align-center">Resources To Help You Sell Your House Fast</h1>



            <p>We’ve compiled some resources on this page to help you sell your house fast or save you from foreclosure. We work with homeowners every day who need to sell their house but can’t do it the traditional route through a real estate agent… and people going through foreclosure. So, use us as a resource too.&nbsp;We’d be glad to answer your questions, help you better understand your options, and walk you through how our “home buying” service works… where we’ll make you a fair no-obligation, no-hassle offer.&nbsp;It’s simple, straightforward, and just may be perfect for you.</p>
            <p>Check out these resources and links below…</p>

            <p><strong><a href="/go/selling-to-a-professional/" target="_blank" rel="noopener">Free Guide: The Pros and Cons Of Selling A House To A Professional House Buyer&nbsp;–</a></strong> We wrote this guide <img loading="lazy" class="alignleft" src={SellingToAProfessional} alt="cash home sale in CA" width="104" height="166" />this year to walk through your 3 options when you need to sell your house. 1) Working with an agent. 2) Selling it yourself.&nbsp;3) Selling to a real estate investor. This guide will tell you the pros, cons, costs, and timeline associated with each option… and it’ll show you which options make sense in what situations. <a href="/go/selling-to-a-professional/">Download the guide free here &lt;&lt;</a></p>
            <p><a href="http://www.washingtonpost.com/blogs/where-we-live/post/selling-a-home-to-a-real-estate-investor/2012/12/11/5907944e-40bb-11e2-a2d9-822f58ac9fd5_blog.html" target="_blank" rel="noopener">Washington Post Article On Selling To A Real Estate Investor </a>– Good solid article that walks through how it works to sell to a real estate investor. They’re spot on with their recommendation to make sure you look for an investor who can deliver on their promise <em>(some investors may not be able to actually close on their offer. Every offer we make we back up by being able to close on that offer.)</em></p>
            <hr />
            <h2>Foreclosure Resources and Information –</h2>
            <p><a href="http://www.fdic.gov/consumers/loans/prevention/index.html" target="_blank" rel="noopener"><strong>FDIC Foreclosure Prevention Information</strong> </a>– The FDIC is a government entity and created a great resource and “Foreclosure Prevention Toolkit”. If you’re in foreclosure check it out.</p>
            <p><strong><a href="http://www.uac.org/foreclosurehelp.htm" target="_blank" rel="noopener">Foreclosure Prevention Guide</a> –</strong> The Urban Affairs Coalition has a great <a href="/" target="_blank" rel="noopener">foreclosure prevention</a>&nbsp;guide to walk you through the foreclosure process.</p>
            <p><strong>5 Ways To Stop or Avoid Foreclosure In Today’s Market – FREE Guide:</strong></p>
            <p><img loading="lazy" class="alignleft" src={FiveWayForeclosure} width="96" height="108" />Need more information on the foreclosure process and&nbsp;<strong>How To Stop Foreclosure</strong>?&nbsp;<a href="/go/stop-foreclosure/"><strong>Download our FREE Stop Foreclosure Guide here</strong>.</a>Or, you can always feel free to&nbsp;<a href="/contact-us/">Contact us</a>&nbsp;anytime if you have questions, want a&nbsp;no hassle&nbsp;Situation Evaluation, or want to just learn more about how we can help homeowners avoid foreclosure or sell unwanted properties for cash.</p>


          </div>
          <div className="w-full md:w-1/3 md:pl-4">



            <div className="rounded-xl mb-2 shadow bg-white  mt-6 md:mt-0">
              <div className="px-8 pt-8">
                <h2 className="text-gray-800 font-bold text-2xl md:text-3xl leading-8">Get Your Fair Cash Offer: Start Below!</h2>
                <p class="text-gray-600 mb-2 leading-6 mt-1">We buy houses in any condition -- no commissions or fees and no obligation whatsoever. Start below by giving us a bit of information about your property or call 916-237-9457.</p>
              </div>


              <div className="p-8 pt-4"><ContactForm stacked={true} hideLabels={true} showPlaceholders={true} /></div>

            </div>
            <SeeTheDifferenceSidebar />
          </div>
        </div>
      </section>




    </Layout>
  );
};


export default ResourcePage;

